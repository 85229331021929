import { Table, TableContainer, TableHead, TableRow, TableBody, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase } from '../../Global';
import { getServiceData, postServiceData, saveServiceData } from "../../store/global-services";
import { IGlobalPoprop } from "../../interfaces/global-interface";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
let { UrlBase } = UrlSiteBase();

const Inventario = (globalProps: IGlobalPoprop) => {
    const [inventario, setInventario] = useState<any>();
    const [idDistribuidora, setIdDistribuidoras] = useState<string>('0');
    const [distribuidoras, setDistribuidoras] = useState<any>([]);
    const navigate = useNavigate();
    const handlePrecio = (idProducto: number, value: string, type: string)  => {
        if (!/^[0-9\.]+$/.test(value) && value !== '') 
           return;
        inventario.map((inv: any) => {
            if (inv.idProducto === idProducto) {
                if (type === "cantidad")
                    inv.cantidad = value;
            }
        });
        setInventario([...inventario]);
    }
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setIdDistribuidoras(value);
    }
    const handleChecked = (id: number) => {
        if (id === 0) return;
        inventario?.map((inv:any) => {
            if (inv.id === id){
                let isActive = inv.estatus === 1;
                inv.estatus = isActive ? 0 : 1;
            }
        });
        setInventario([...inventario]);
    }
    const getSafeValue = (field: any) => {
        return field === undefined || field === null ? '' : field;
    }
    useEffect(() => {
        getServiceData({ nombre: 'distribuidoras', parametros: {estatus: 1}}).then(data => {
            if (data.success && data.distribuidoras)
              setDistribuidoras([...data.distribuidoras]);
        });
    }, []);
    useEffect(() => {
        loadInventario();
    }, [idDistribuidora]);
    const saveClick = () => {
        saveServiceData({ reqMethod: 'PUT', nombre: 'inventario', datos : { idDistribuidora: idDistribuidora, inventario: inventario }}).then((data) => {
            if (data.success) {
                loadInventario();
                if (globalProps && globalProps.snackHandleOpen)
                    globalProps.snackHandleOpen({open: true, message: data.message});
            }
        });
    }
    const loadInventario = () => {
        postServiceData({ nombre: 'inventario', datos : { idDistribuidora: idDistribuidora }}).then((data) => {
            if (data.success)
                setInventario([...data.inventario]);
        });
    }
    return (
        <>
            <div className="panel-actions floating">
                <FormControl className="select-control">
                    <InputLabel id="perfil-label">Distribuidora</InputLabel>
                    <Select labelId="Distribuidora" id="distribuidora" name="idDistribuidora" value={idDistribuidora} label="Distribuidora" onChange={handleSelectChange}>
                        <MenuItem value={0}>Bodega Central</MenuItem>
                            {distribuidoras?.map((distribuidora: any) => (
                                <MenuItem key={"item-" + distribuidora?.id} value={distribuidora?.id}>{distribuidora?.nombre}</MenuItem>
                            ))}
                    </Select>
                </FormControl>               
                <ButtonGST onClick={() => saveClick()}>Guardar</ButtonGST>
            </div>
            <TableContainer>
                <Table className="table-gts">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Producto</StyledTableCell>
                            <StyledTableCell>Presentacion</StyledTableCell>
                            <StyledTableCell>Cantidad</StyledTableCell>
                            <StyledTableCell>Estatus</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inventario?.map((inv: any) => (
                            <StyledTableRow key={inv.idProducto + ' ' + inv.id }>
                                <StyledTableCell>{inv.nombre}</StyledTableCell>
                                <StyledTableCell>{inv.presentacion}</StyledTableCell>
                                <StyledTableCell><TextField value={getSafeValue(inv.cantidad)} error={inv?.errorCantidad !== ''}  helperText={inv?.errorCantidad} onChange={(event) => handlePrecio(inv.idProducto, event.target.value, "cantidad")} /></StyledTableCell>
                                <StyledTableCell><SwitchContainerGTS checked={inv.estatus === 1} onChange={() => handleChecked(inv.id)}  /></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Inventario;