import ListadoUsuarios from './Administracion/listado-usuarios';
import ListadoPerfil from './Administracion/listado-perfiles';
import Perfil from './Administracion/perfil';
import Usuario from './Administracion/usuario';
import Permisos from './Administracion/permisos';
import ListadoPedidos from './Compras/listado-pedidos';
import ListadoClientes from './Catalogos/listado-clientes';
import ListadoProductos from './Catalogos/listado-productos';
import Producto from './Catalogos/producto';
import Cliente from './Catalogos/cliente';
import ListadoClienteProspecto from './Catalogos/listado-clientes-prospecto';
import ClienteProspecto from './Catalogos/cliente-prospecto';
import Venta from './Ventas/venta';
import ListadoVentas from './Ventas/listado-ventas';
import ListadoDistribuidoras from './Catalogos/listado-distribuidoras';
import Distribuidora from './Catalogos/distribuidora';

import ReportePedidos from './Compras/reporte-pedidos';
import { getServiceData } from "../store/global-services";
import React, { useEffect, useState } from "react";
import ListadoPrecios from './Catalogos/listado-precios';
import Inventario from './Catalogos/inventario';


const ScreenManager = (props: any) => {
    const { screen, snackHandleOpen } = props;
    const [ section, setSection ] = useState<any>(<></>); //
    const Secciones: any = {
        ListadoUsuarios: '5',
        Usuario: '5',
        ListadoPerfil: '6',
        Perfil: '6',
        Permisos: '7',
        Producto: '8',
        ListadoProductos: '8',
        Distribuidora: '9',
        ListadoDistribuidoras: '9',
        ListadoClientes: '10',
        Cliente: '10',
        ListadoClientesProspecto: '11',
        ClienteProspecto: '11',
        Venta: '12',
        ListadoVentas: '15',
        ListadoPrecios: '17',
        Inventario: '18',
        /////////------------------------------------
        //ListadoPedidos: '12',
        //ReportePedidos: '15',
        
        
    }
    useEffect(() => {
        setSection(<></>);
        getServiceData({ nombre: 'usuario/acceso/' + Secciones[screen] }).then(data => {
            if (data.success)
                setSection(getSection(screen, snackHandleOpen));
            else
                snackHandleOpen({ open: true, message: data.message, color: 'red' });
        }); 
    }, [screen]);
    return <>{section}</>;
};
const getSection = (screen: string, snackHandleOpen: any) => {
    switch (screen) {
        case 'ListadoUsuarios':
            return <ListadoUsuarios snackHandleOpen={snackHandleOpen} />;
        case 'Usuario': 
            return <Usuario snackHandleOpen={snackHandleOpen} />;
        case 'ListadoPerfil':
            return <ListadoPerfil snackHandleOpen={snackHandleOpen} />;
        case 'Perfil':
            return <Perfil snackHandleOpen={snackHandleOpen} />;
        case 'Permisos':
            return <Permisos snackHandleOpen={snackHandleOpen} />;
        case 'ListadoProductos':
            return <ListadoProductos snackHandleOpen={snackHandleOpen} />;
        case 'Producto':
            return <Producto snackHandleOpen={snackHandleOpen} />;
        case 'ListadoDistribuidoras':
            return <ListadoDistribuidoras snackHandleOpen={snackHandleOpen} />;
        case 'Distribuidora':
            return <Distribuidora snackHandleOpen={snackHandleOpen} />;
        case 'ListadoClientes':
            return <ListadoClientes snackHandleOpen={snackHandleOpen} />;
        case 'Cliente':
            return <Cliente snackHandleOpen={snackHandleOpen} />;
        case 'ListadoClientesProspecto':
            return <ListadoClienteProspecto snackHandleOpen={snackHandleOpen} />;
        case 'ClienteProspecto':
            return <ClienteProspecto snackHandleOpen={snackHandleOpen} />;
        case 'Venta':
            return <Venta snackHandleOpen={snackHandleOpen} />;
        case 'ListadoVentas':
            return <ListadoVentas snackHandleOpen={snackHandleOpen} />;
        case 'ListadoPrecios':
            return <ListadoPrecios snackHandleOpen={snackHandleOpen} />;
        case 'Inventario':
            return <Inventario snackHandleOpen={snackHandleOpen} />;
        /////////------------------------------------
        case 'ListadoPedidos':
            return <ListadoPedidos snackHandleOpen={snackHandleOpen} />;
        case 'ReportePedidos':
            return <ReportePedidos snackHandleOpen={snackHandleOpen} />;
        default:    
            return <></>;
    }   
}

export default ScreenManager;