import { Table, TableContainer, TableHead, TableRow, TableBody, IconButton, Pagination, Stack, TextField, InputAdornment } from "@mui/material";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getServiceData } from "../../store/global-services";
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
import { UrlSiteBase } from '../../Global';
let { UrlBase } = UrlSiteBase();

const ListadoVentas = (globalProp?: IGlobalPoprop) => {
    const [ventas, setVentas] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0); 
    const navigate = useNavigate();
    const handleClick = (idVenta: number) => {
        navigate(idVenta > 0 ? `${UrlBase}Venta/${idVenta}` : `${UrlBase}Venta`);
    };
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    useEffect(() => {
        getServiceData({ nombre: 'ventas', parametros: { estatus: 1 }}).then(data => {
          if (data.success && data.ventas)
            setVentas([...data.ventas]);
        });
      }, []);
    return (
        <>
            <div className="panel-actions">
                <ButtonGST onClick={() =>handleClick(0)}>Agregar <AddIcon /></ButtonGST>
            </div>
            <TableContainer>
                <Table className="table-gts">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Distribuidora</StyledTableCell>
                            <StyledTableCell>Cliente</StyledTableCell>
                            <StyledTableCell>Fecha Venta</StyledTableCell>
                            <StyledTableCell className="movile-hidden">Total</StyledTableCell>
                            <StyledTableCell className="movile-hidden">Productos</StyledTableCell>
                            <StyledTableCell className="movile-hidden">Almacenaje</StyledTableCell>
                            <StyledTableCell>Activo</StyledTableCell>
                            <StyledTableCell >Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ventas?.map((venta: any) => (
                            <StyledTableRow key={venta.id}>
                                <StyledTableCell>{venta?.idDistribuidora == 0 ? 'Sin Distribuidora' : venta?.distribuidora}</StyledTableCell>
                                <StyledTableCell>{venta?.idCliente == 0 ? 'Venta de Mostrador' : venta?.cliente}</StyledTableCell>
                                <StyledTableCell>{venta?.fechaVenta}</StyledTableCell>
                                <StyledTableCell className="movile-hidden">{venta?.importeTotalIVA	}</StyledTableCell>
                                <StyledTableCell className="movile-hidden">{venta?.totalProductos}</StyledTableCell>
                                <StyledTableCell className="movile-hidden"><SwitchContainerGTS checked={venta?.ventaAlmacenada === 1}  /></StyledTableCell>
                                <StyledTableCell><SwitchContainerGTS checked={venta?.estatus === 1}  /></StyledTableCell>
                                <StyledTableCell>
                                    {/*<ButtonGST onClick={() => handleClick(venta.id)}><EditIcon /></ButtonGST>*/}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))} 
                    </TableBody>
                </Table>
            </TableContainer>
            { totalPages > 1 ?
                <Stack spacing={2}>
                <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
                </Stack>
                : <></>
            }
        </>
    )
};

export default ListadoVentas;
    