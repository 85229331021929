import { Table, TableContainer, TableHead, TableRow, TableBody, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase } from '../../Global';
import { getServiceData, postServiceData, saveServiceData } from "../../store/global-services";
import { IGlobalPoprop } from "../../interfaces/global-interface";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
let { UrlBase } = UrlSiteBase();

const ListadoPrecios = (globalProps: IGlobalPoprop) => {
    const [precios, setPrecios] = useState<any>();
    const [tipoPrecio, setTipoPrecio] = useState<any>();
    const [idTipoPrecio, setIdTipoPrecio] = useState<string>('0');
    const [idDistribuidora, setIdDistribuidoras] = useState<string>('0');
    const [distribuidoras, setDistribuidoras] = useState<any>([]);
    const navigate = useNavigate();

    const handlePrecio = (idProducto: number, value: string, type: string)  => {
        if (!/^[0-9\.]+$/.test(value) && value !== '') 
           return;
        precios.map((precio: any) => {
            if (precio.idProducto === idProducto) {
                if (type === "precio")
                    precio.precio = value;
                if (type === "cantidadMaxima")
                    precio.cantidadMax = value;
                if (type === "cantidadMinima")
                    precio.cantidadMin = value;
            }
        });
        setPrecios([...precios]);
    }
    const handleChecked = (id: number) => {
        if (id === 0) return;
        precios?.map((prec:any) => {
            if (prec.id === id){
                let isActive = prec.estatus === 1;
                prec.estatus = isActive ? 0 : 1;
            }
        });
        setPrecios([...precios]);
    }
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setIdTipoPrecio(value);
    }
    const getSafeValue = (field: any) => {
        return field === undefined || field === null ? '' : field;
    }
    useEffect(() => {
        getServiceData({ nombre: 'distribuidoras', parametros: {estatus: 1}}).then(data => {
            if (data.success && data.distribuidoras)
              setDistribuidoras([...data.distribuidoras]);
        });
        getServiceData({ nombre: 'tipos-precios' }).then((data) => {
            if (data.success)
                setTipoPrecio([...data.tiposPrecios]);
        });
        
    }, []);
    useEffect(() => {
        if (idTipoPrecio === '0')
            return;
        loadPrecios();
    }, [idTipoPrecio, idDistribuidora]);
    const saveClick = () => {
        saveServiceData({ reqMethod: 'PUT', nombre: 'lista-precios', datos : { idTipoPrecio: idTipoPrecio, listaPrecios: precios }}).then((data) => {
            if (data.success) {
                loadPrecios();
                if (globalProps && globalProps.snackHandleOpen)
                    globalProps.snackHandleOpen({open: true, message: data.message});
            }
        });
    }
    const loadPrecios = () => {
        postServiceData({ nombre: 'lista-precios', datos : { idTipoPrecio: idTipoPrecio, idDistribuidora: idDistribuidora }}).then((data) => {
            if (data.success)
                setPrecios([...data.listaPrecios]);
        });
    }
    return (
        <>
            <div className="panel-actions floating">
                { true ? <></> :
                    <FormControl className="select-control">
                        <InputLabel id="perfil-label">Distribuidora</InputLabel>
                        <Select labelId="Distribuidora" id="distribuidora" name="idDistribuidora" value={idDistribuidora} label="Distribuidora" onChange={handleSelectChange}>
                            <MenuItem value={0}>Todas</MenuItem>
                                {distribuidoras?.map((distribuidora: any) => (
                                    <MenuItem key={"item-" + distribuidora?.id} value={distribuidora?.id}>{distribuidora?.nombre}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                }
                <FormControl className="select-control">
                    <InputLabel id="perfil-label">Tipos de precios</InputLabel>
                    <Select labelId="Perfil" id="perfil" name="idTipoPrecio" value={idTipoPrecio} label="Tipos de precios" onChange={handleSelectChange}>
                        <MenuItem key={"perf-0"} value="0">Selecciona un tipo de precio...</MenuItem>
                        {tipoPrecio?.map((p:any) => <MenuItem key={"perf-" + p.id} value={p.id}>{p.nombre}</MenuItem>)}
                    </Select>
                </FormControl>
                { idTipoPrecio === '0' ? <></> : <ButtonGST onClick={() => saveClick()}>Guardar</ButtonGST> }
            </div>
            {idTipoPrecio === '0' ? <></> :
                <TableContainer>
                    <Table className="table-gts">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Producto</StyledTableCell>
                                <StyledTableCell>Presentacion</StyledTableCell>
                                <StyledTableCell>Precio</StyledTableCell>
                                <StyledTableCell>Cant. Min.</StyledTableCell>
                                <StyledTableCell>Cant. Max.</StyledTableCell>
                                <StyledTableCell>Estatus</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {precios?.map((precio: any) => (
                                <StyledTableRow key={precio.idProducto}>
                                    <StyledTableCell>{precio.nombre}</StyledTableCell>
                                    <StyledTableCell>{precio.presentacion}</StyledTableCell>
                                    <StyledTableCell><TextField value={getSafeValue(precio.precio)} error={precio?.errorPrecio !== ''}  helperText={precio?.errorPrecio} onChange={(event) => handlePrecio(precio.idProducto, event.target.value, "precio")} /></StyledTableCell>
                                    <StyledTableCell><TextField value={getSafeValue(precio.cantidadMin)} error={precio?.errorCantidadMin !== ''}  helperText={precio?.errorCantidadMin} onChange={(event) => handlePrecio(precio.idProducto, event.target.value, "cantidadMinima")} /></StyledTableCell>
                                    <StyledTableCell><TextField value={getSafeValue(precio.cantidadMax)} error={precio?.errorCantidadMax !== ''}  helperText={precio?.errorCantidadMax} onChange={(event) => handlePrecio(precio.idProducto, event.target.value, "cantidadMaxima")} /></StyledTableCell>
                                    <StyledTableCell><SwitchContainerGTS checked={precio.estatus === 1} onChange={() => handleChecked(precio.id)}  /></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

export default ListadoPrecios;