import { IconButton, TextField, InputAdornment } from "@mui/material";
import DialogGTS from "../../molecules/DialogGTS";
import ListClientGTS from "../../molecules/ListClientGTS";
import ListProductGTS from "../../molecules/ListProductGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { TabsGTS, TabPanelGTS} from "../../molecules/TabsGTS";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { useState, useEffect } from "react";
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
import { IVenta, IVentaProducto, VentaDefault, IVentaError, VentaError, VentaProductoDefault } from "../../interfaces/venta-interface";
import { ICliente, ClienteDefault  } from "../../interfaces/cliente-interface";
import { IProductoVenta, ProductoVentaDefault } from "../../interfaces/producto-interface";
import { getServiceData, saveServiceData, postServiceData } from "../../store/global-services";
import { useNavigate, useParams } from "react-router-dom";
import { UrlSiteBase } from '../../Global';
let { UrlBase } = UrlSiteBase();

const Venta = (globalProp?: IGlobalPoprop) => {
    const CliDefVenta = ClienteDefault;
    CliDefVenta.nombres = 'Cliente de Mostrador';
    CliDefVenta.correo_electronico = 'N/A';
    CliDefVenta.telefono_uno = 'N/A';
    CliDefVenta.telefono_dos = 'N/A';
    const { idVenta } = useParams();
    const [clienteVenta, setClienteVenta] = useState<ICliente>(CliDefVenta);
    const [clientes, setClientes] = useState<ICliente[]>([]);
    const [venta, setVenta] = useState<IVenta>(VentaDefault);
    const [error, setError] = useState<IVentaError>(VentaError);
    const [productos, setProductos] = useState<IProductoVenta[]>([]);
    const [productosVenta, setProductosVenta] = useState<IVentaProducto[]>([]);
    const [prodVentaCalc, setProdVentaCalc] = useState<IVentaProducto[]>([]);
    const [productosAlmacenaje, setProductosAlmacenaje] = useState<IVentaProducto[]>([]);
    const [prodAlmacenSel, setProdAlmacenSel] = useState<IVentaProducto[]>([]);
    const [tabSelected, setTabSelected] = useState<number>(0);
    const [openDialogProduct, setOpenDialogProduct] = useState<boolean>(false);
    const [openDialogProdStore, setOpenDialogProdStore] = useState<boolean>(false);
    const [openDialogClient, setOpenDialogClient] = useState<boolean>(false);
    const [storeAllProd, setStoreAllProd] = useState<boolean>(false);
    const [textClientSearch, setTextClientSearch] = useState<string>('');
    const [pageClient, setPageClient] = useState<number>(1);
    const [totalPagesClient, setTotalPagesClient] = useState<number>(0);
    const [totalVentas, setTotalVentas] = useState<number>(0);
    const [totalVenCalc, setTotalVenCalc] = useState<number>(0);
    const navigate = useNavigate();
    const handleDelClientClick = (clientId: number) => {
        setClienteVenta(CliDefVenta);
    };
    const handleDelProductClick = (id: number) => {
        
    };
    const handleCloseDialogProduct = () => {
        setOpenDialogProduct(false);
    };
    const handleCloseDialogProdStore = () => {
        setOpenDialogProdStore(false);
    };
    const handleCloseDialogClient = () => {
        setOpenDialogClient(false);
    };
    const handleOpenDialogClient = () => {
        setOpenDialogClient(true);
    };
    const handleOpenDialogProduct = () => {
        setOpenDialogProduct(true);
    };
    const handleOpenDiagProductStore = () => {
        if (storeAllProd)
            return;
        if (productosVenta.length === 0) 
            return;
        setOpenDialogProdStore(true);
        if (productosAlmacenaje.length === 0)
            setProductosAlmacenaje([...cloneProdSale(true)]);
    }
    const cloneProdSale = (zeroSale: boolean = false, prodToClone: any = productosVenta) => {
        let clonSaleProds = JSON.parse(JSON.stringify(prodToClone));
        clonSaleProds.forEach((item: any) => { item.maxCantidad = item.cantidad; item.cantidad = zeroSale ? 0 : item.cantidad });
        return clonSaleProds;
    }
    const handleSearchClientTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTextClientSearch(value);
    };
    const handleStoreAllProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
        let clonSaleProds = [];
        let clonSaleProdsSel = [];
        if (event.target.checked) {
            clonSaleProds = cloneProdSale();
            clonSaleProdsSel = cloneProdSale();
        }
        setStoreAllProd(event.target.checked);
        setProductosAlmacenaje(clonSaleProds);
        setProdAlmacenSel(clonSaleProdsSel);
    }
    const getClientesSearch = () => {
        let dataService: IServiceData  = { nombre: 'clientes' };
        let paramService = {};
        paramService = { ...paramService, 'estatus': 1 };
        if (pageClient > 1) 
            paramService = { ...paramService, 'page': pageClient };
        if (textClientSearch.trim().length > 0)
            paramService = { ...paramService, 'search': textClientSearch.trim() };
        dataService = { ...dataService, 'parametros': paramService };
        getServiceData(dataService).then(data => {
            if (data.success){
                setClientes(data.clientes);
                setTotalPagesClient(data.pagination.last_page);
            }
        });
    }
    const getProductosVenta = () => {
        let dataService: IServiceData  = { nombre: 'productos/venta' };
        let paramService = {};
        paramService = { ...paramService, 'estatus': 1 };
        dataService = { ...dataService, 'parametros': paramService };
        getServiceData(dataService).then(data => {
            if (data.success) {
                let prod = data.productos;
                // Aqui se agregaría el maximo de existencias
                prod.forEach((item: any) => item.maxCantidad = item.cantidadProducto);
                setProductos(prod);
            }
        });
    }
    const getCalculaVenta = (fn: Function) => {
        postServiceData({ nombre: 'calcula-venta', datos: { productos: productosVenta } }).then(data => {
            if (data.success) {
                fn(data);
            }
        })
    }

    const handleClientKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') { 
          getClientesSearch(); 
          event.preventDefault(); 
        }
    };
    const handleSelectedClientClick = (clientSelected: any) => {
        setClienteVenta(clientSelected);
        handleCloseDialogClient();
    };
    const handleSelectedProductClick = (productSelected: any) => {
        setProductosVenta([...productSelected]);
        let totalVtas = 0;
        productSelected.forEach((product: any) => {
            totalVtas = totalVtas + (parseFloat(product.cantidad) * parseFloat(product.precioVenta));
        });
        setTotalVentas(totalVtas);
        if (storeAllProd)
            setProdAlmacenSel(cloneProdSale(false, productSelected));
        else if (prodAlmacenSel.length > 0) {
            prodAlmacenSel.forEach((product: any) => {
                let pro = productSelected.find((item: any) => item.id === product.id);
                if (pro && pro.cantidad < product.cantidad)
                    product.cantidad = pro.cantidad
                else if (!pro)
                    product.cantidad = 0;
            });
            setProdAlmacenSel([...prodAlmacenSel.filter((product: any) => product.cantidad > 0)]);
        }
    };
    const formatObject = new Intl.NumberFormat('en-us', {minimumFractionDigits: 2});
    const numFormat = (num: number) => {
        return formatObject.format(num)
    }
    const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setVenta(venta => ({ ...venta, [id]: value }));
    }
    const handleTabChangeVenta = (idxTab: number) => {
        let bolContinue = true;
        let message = '';
        if ((idxTab === 3 || idxTab === 2) && productosVenta.length == 0) {
            bolContinue = false;
            message = 'Agrega al menos un producto';
            if (globalProp && globalProp.snackHandleOpen)
                globalProp.snackHandleOpen({open: true, message: message, color: 'red'});
        }
        if (bolContinue)
        {
            if (idxTab === 3) {
                getCalculaVenta((data: any) => {
                    if (data.success) {
                        setProdVentaCalc(data.venta.productos);
                        setTotalVenCalc(data.venta.total);
                        setTabSelected(idxTab);
                    }
                    else if (globalProp && globalProp.snackHandleOpen)
                        globalProp.snackHandleOpen({open: true, message: data.message, color: 'red'});
                });
            }
            else    
                setTabSelected(idxTab);
        }
        return bolContinue;
    }
    const handleDelProdStoreClick = (id: number) => {
        setProdAlmacenSel([...prodAlmacenSel.filter((product: any) => product.id !== id)]);
        productosAlmacenaje.forEach((product: any) => product.cantidad = product.id !== id ? product.cantidad : 0);
        setProductosAlmacenaje(productosAlmacenaje);
        setStoreAllProd(false);
    }
    const handleSelProdStoreClick = (productSelected: any) => {
        setProdAlmacenSel([...productSelected]);
    }
    const guardarVenta = () => {
        let ventaSend = { ...venta, 
            'idCliente': clienteVenta.id,
            'tipoVenta': 1,
            'ventaAlmacenada': prodAlmacenSel.length > 0 ? true : false,
            'productos': productosVenta, 
            'productosAlmacenados': prodAlmacenSel
        };
        saveServiceData( {nombre: 'venta', id: idVenta, datos: ventaSend}).then( data => {
            if (data.success) {
              setVenta(VentaDefault);
              setClienteVenta(ClienteDefault);
              productos.forEach((product: any) => product.cantidad = 0)
              setProductos(productos);
              setProductosVenta([]);
              setProdAlmacenSel([]);
              setProductosAlmacenaje([]);
              setTotalVentas(0);
              setTabSelected(0);
              if (globalProp && globalProp.snackHandleOpen)
                globalProp.snackHandleOpen({open: true, message: data.message});
            }
            else {
              setError(VentaError);
              for (const [key, value] of Object.entries(data.message)) {
                setError(error => ({...error, [key]: value}));
              }
            }
          });
    }
    useEffect(() => {
        getProductosVenta()
    }, []);
    const getSafeValue = (field: any) => {
        return field === undefined || field === null ? '' : field;
    };
    return (<>
        <TabsGTS labels={['Cliente', 'Productos', 'Almacenaje', 'Resumen']} handleTabChange={handleTabChangeVenta} tabSelected={tabSelected} >
            <TabPanelGTS index={0} value={tabSelected} className="tabpanel-client">
                <div className="panel-actions"><ButtonGST onClick={handleOpenDialogClient}>Buscar Cliente <SearchIcon /></ButtonGST></div>
                <DialogGTS title="Selecciona un cliente" open={openDialogClient} handleClose={handleCloseDialogClient} >
                    <div className="panel-search">
                        <TextField id="search-client" onChange={handleSearchClientTextChange} value={textClientSearch} label="Buscar Cliente" color="secondary"
                            InputProps={{ endAdornment: 
                                <InputAdornment position="end">
                                <IconButton onClick={getClientesSearch}>
                                    <SearchIcon />
                                </IconButton>
                                </InputAdornment> }} onKeyDown={handleClientKeyDown}/>
                    </div>
                    <ListClientGTS key="list-client-search" screen="venta-busqueda" clientes={clientes} handleSelectedClick={handleSelectedClientClick} />
                </DialogGTS>
                <ListClientGTS key="list-client" screen="venta" clientes={[clienteVenta]} handleDeleteClick={handleDelClientClick} />
            </TabPanelGTS>
            <TabPanelGTS index={1} value={tabSelected} className="tabpanel-products">
                <div className="panel-actions"><ButtonGST onClick={handleOpenDialogProduct}>Agregar Producto <AddIcon /></ButtonGST></div>
                <ListProductGTS key="list-product-sales" screen="venta" productos={productosVenta} handleDeleteClick={handleDelProductClick}  />
                <div className="panel-totals">
                    <p className="total"><span className="label">Importe Total:</span><span className="value">${numFormat(totalVentas)}</span> </p>
                </div>
                <DialogGTS title="Agrega Productos" open={openDialogProduct} handleClose={handleCloseDialogProduct} >
                    <ListProductGTS key="list-product-sales-search" screen="venta-busqueda" productos={productos} useMaxValue={true} handleSelectedClick={handleSelectedProductClick} />
                </DialogGTS>
            </TabPanelGTS>
            <TabPanelGTS index={2} value={tabSelected} className="tabpanel-store-products">
                <div className="panel-actions flex-space-between reduce-font-mobile">
                    <SwitchLabelGTS className="" label="Almacenar Todo" id="swStoreAllProd" checked={storeAllProd} onChange={handleStoreAllProduct} />
                    <ButtonGST onClick={handleOpenDiagProductStore}>Almacenar Producto <EditIcon /></ButtonGST>
                </div>
                <ListProductGTS  key="list-product-store" screen="venta-almacenaje" productos={prodAlmacenSel} handleDeleteClick={handleDelProdStoreClick}  />
                <DialogGTS title="Almacenar Productos" open={openDialogProdStore} handleClose={handleCloseDialogProdStore} >
                    <ListProductGTS  key="list-product-store-search" screen="venta-busqueda" subScreen="almacenaje" useMaxValue={true} productos={productosAlmacenaje} handleSelectedClick={handleSelProdStoreClick} />
                </DialogGTS>
            </TabPanelGTS>
            <TabPanelGTS index={3} value={tabSelected} className="tabpanel-resume">
                <h4 className="sub-title">Cliente</h4>
                <ListClientGTS key="list-client-resume" screen="venta" subScreen="resume" clientes={[clienteVenta]} />
                <h4 className="sub-title">Productos</h4>
                <ListProductGTS key="list-product-sales-resume" screen="venta" productos={prodVentaCalc} />
                <h4 className="sub-title">Almacenaje</h4>
                <ListProductGTS  key="list-product-store-screen" screen="venta-almacenaje" subScreen="resume" productos={prodAlmacenSel} />
                <div className="panel-totals">
                    <p className="total"><span className="label">Importe Total:</span><span className="value">${numFormat(totalVenCalc)}</span> </p>
                </div>
                <div>
                <TextField id="observaciones" className="medium-textarea" onChange={handleInputTextChange} multiline rows={2}
                    value={getSafeValue(venta?.observaciones)} label="Observaciones" color="secondary" error={error.observaciones !== ''}  helperText={error.observaciones} />
                </div>
                <div className="panel-actions center">
                    <ButtonGST onClick={() => navigate(UrlBase + 'Venta')}>Cancelar</ButtonGST>
                    <ButtonGST onClick={guardarVenta}>Guardar Venta</ButtonGST>
                </div>
            </TabPanelGTS>
        </TabsGTS>
    </>);
}

export default Venta;